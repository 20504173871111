<template>
  <div>
    <div class="prese_view">
      <div>
        <el-select v-model="tag" size="small">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>&nbsp;&nbsp;&nbsp;
        <el-tag
          :effect="version=='boot'?'dark':'plain'"
          @click="version='boot'"
          style="width:100px;text-align:center;cursor:pointer"
        >欢迎界面</el-tag>&nbsp;&nbsp;&nbsp;
        <el-tag
          :effect="version=='main'?'dark':'plain'"
          @click="version='main'"
          style="width:100px;text-align:center;cursor:pointer"
        >主界面</el-tag>
      </div>
      <div style="width:350px"></div>
    </div>

    <JianYueBoot v-if="tag=='jianyue' && version=='boot'"  :isDefault="true" :isPrese="true"></JianYueBoot>
    <YueMuBoot v-if="tag=='yuemu' && version=='boot'"  :isDefault="true" :isPrese="true"></YueMuBoot>
    <ShiShangBoot v-if="tag=='shishang' && version=='boot'"  :isDefault="true" :isPrese="true"></ShiShangBoot>
    <HuaLiBoot v-if="tag=='huali' && version=='boot'"  :isDefault="true" :isPrese="true"></HuaLiBoot>


    <JianYueMain v-if="tag=='jianyue' && version=='main'" :isTag="tag" :isDefault="true" :isPrese="true"></JianYueMain>
    <YueMuMain v-if="tag=='yuemu' && version=='main'" :isTag="tag" :isDefault="true" :isPrese="true"></YueMuMain>
    <ShiShangMain v-if="tag=='shishang' && version=='main'" :isTag="tag" :isDefault="true" :isPrese="true"></ShiShangMain>
    <HuaLiMain v-if="tag=='huali' && version=='main'" :isTag="tag" :isDefault="true" :isPrese="true"></HuaLiMain>
  </div>
</template>
<script>
import JianYueMain from "./main/JianYue";
import YueMuMain from "./main/YueMu";
import ShiShangMain from "./main/ShiShang";
import HuaLiMain from "./main/HuaLi";
import JianYueBoot from "./boot/JianYue";
import YueMuBoot from "./boot/YueMu";
import ShiShangBoot from "./boot/ShiShang";
import HuaLiBoot from "./boot/HuaLi";
export default {
  components: {
    JianYueMain,
    YueMuMain,
    ShiShangMain,
    HuaLiMain,
    JianYueBoot,
    YueMuBoot,
    ShiShangBoot,
    HuaLiBoot
  },
  data() {
    return {
      tag: "jianyue",
      version: "boot",
      options: [
        { value: "jianyue", label: "简约版" },
        { value: "yuemu", label: "悦目版" },
        { value: "shishang", label: "时尚版" },
        { value: "huali", label: "华丽版" }
      ]
    };
  },
  methods: {
  } 
};
</script>
<style lang='scss'>
.prese_view {
  position: absolute;
  top: 10px;
  z-index: 99;
}
</style>